import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";


export default function FileUploadComponent() {
  const [file, setFile] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [selectedDate, setSelectedDate] = useState(new dayjs());
  const [sendEmailNotification, setSendEmailNotification] = useState(false);

  const { user, isAuthenticated, isLoading } = useAuth0();


  useEffect(() => {
    // Assuming your API returns an array of company objects with 'id' and 'name' properties
    axios
      .get(`${process.env.REACT_APP_APIURL}/api/all/companies`)
      .then((response) => {
        console.log(response.data);
        setCompanies(response.data);
      });
  }, []);

  const handleUpload = () => {
    if (file && selectedCompany) {
      const formData = new FormData();

      const formattedDate = selectedDate.format("YYYY-MM-DD");
      const fileExtension = file.name.slice(
        ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      const newFilename = `${formattedDate}.${fileExtension}`;

      formData.append("file", file, newFilename); // Setting the filename for the uploaded file

      axios
      .post(
        `${process.env.REACT_APP_APIURL}/api/${selectedCompanyName}/upload`,
        formData
      )
      .then(() => {
        setUploadSuccess(true);
        setFile(null); 
        setSelectedCompany(""); 

        // Check if the checkbox is checked before sending the email
        if (sendEmailNotification) {
          axios
            .post(`${process.env.REACT_APP_APIURL}/send-email`, {
              to: user.email,
              subject: "Argus100 | There's an update for you!",
              company: selectedCompanyName
            })
            .catch((error) => {
              console.error("Error sending notification email:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ ml: 10, mt: 2, width: "500px" }}>
        <h2>Upload File to Company</h2>

        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel id="company-select-label">Company</InputLabel>
          <Select
            labelId="company-select-label"
            value={selectedCompany}
            onChange={(e) => {
              setSelectedCompany(e.target.value);
              setSelectedCompanyName(companies[e.target.value - 1].company);
            }}
            label="Company"
          >
            {companies.map((company) => (
              <MenuItem key={company._id} value={company._id}>
                {company.company}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <input
            accept="text/csv/pdf"
            id="contained-button-file"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            style={{ display: "none" }}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="span">
              Choose File
            </Button>
          </label>
          <Box sx={{ ml: 2 }}>{file ? file.name : "No file chosen"}</Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <DatePicker
            label="Date of file Creation"
            // defaultValue={dayjs('2022-04-17')}
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <input
        type="checkbox"
        checked={sendEmailNotification}
        onChange={() => setSendEmailNotification(!sendEmailNotification)}
      />
      <label htmlFor="sendEmailNotification">Send email notification</label>
    </Box>

        <Button variant="contained" color="primary" onClick={handleUpload}>
          Upload
        </Button>

        <Snackbar
          open={uploadSuccess}
          autoHideDuration={4000}
          onClose={() => setUploadSuccess(false)}
          message="File uploaded successfully!"
        />
      </Box>
    </LocalizationProvider>
  );
}
